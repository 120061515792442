import React from 'react'
import Layout from '../../components/Layout'
import { graphql } from 'gatsby';
import Head from '../../components/Head';


export default function About({ data }) {

    const { html, tableOfContents } = data.markdownRemark

    return (
        <Layout>
            <Head title="About Us" />
            <div className="container">
                <section>
                    <div className="row text-muted shadow-lg">
                        <div className="col-lg-3 lh-lg dark-contrast-border p-2">
                            <div className="sticky-top">
                                <h1 className="brand-color fw-bold text-end" style={{ fontSize: "3.5rem" }}>about us.</h1>
                                <p className="fw-light text-end">
                                    our journey.
                                </p>
                                <ul className="text-end page-nav">
                                    <div dangerouslySetInnerHTML={{ __html: tableOfContents }} />
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9 dark-contrast fw-light p-3">
                            <small className="text-muted">Get to know us and read all about us.</small>
                            <div className="mt-5"></div>
                            <div className="mb-5">
                                <section id="about-us" className="page-content">
                                    <div dangerouslySetInnerHTML={{ __html: html }} className="lh-lg"/>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export const query = graphql`
query AboutUsQuery {
    markdownRemark(frontmatter: {slug: {eq: "about-us"}}) {
        html
        tableOfContents
        }
    }
`